/* Customize your main colors in :root variables */
:root {
  --main-background-color: #fff;
  --card-background-color: #222;
  --countdown-background-color: #333;
  --main-text-color:#FFF;
  --title-text-color:#fff;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("img/candy-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}